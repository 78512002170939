<template>
    <v-container fluid>
        <v-layout wrap>
            <v-flex fluid>
                <v-card outlined>
                    <v-card class="px-2">
                        <h3>Produtividade</h3>
                        <v-col>
                            <v-row cols="12" sm="6">
                                <v-text-field label="Data Inicial" outlined dense type="date"></v-text-field>
                                <v-text-field label="Data Final" outlined dense type="date"></v-text-field>
                            </v-row>
                            <v-row cols="12" sm="6">
                                <v-autocomplete outlined dense chips small-chips label="Paciente"></v-autocomplete>
                            </v-row>
                            <v-row>
                                <v-card-text>
                                    <v-data-table :headers="headers" :items="desserts" class="elevation-3"></v-data-table>
                                </v-card-text>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
export default {
    name: "produtividade_equipe_multi'",
    desserts: [],
    data: () => ({
        value: 0,
        headers: [
            { text: 'Paciente', align: 'start', sortable: true, value: 'paciente' },
            { text: 'Procedimento', align: 'start', sortable: true, value: 'procedimento' },
            { text: 'Data da Evolução', align: 'start', sortable: true, value: 'data_evol' },
            { text: 'Data da Assinatura', align: 'start', sortable: true, value: 'data_ass' },
            { text: 'Status', align: 'start', sortable: true, value: 'status' },
        ],
    }),
    created() {
        this.initialize()
    },
    methods: {
        initialize() {
            this.desserts = [
                {
                    paciente: 'Mauro Teste de Sousa',
                    procedimento: 'fisioterapia',
                    data_evol: '10/12/2022',
                    data_ass: '10/12/2022',
                    status: 'Confirmado',
                }
            ]
        },
    }
}
</script>
